<template>
  <div class="nuxt-required">
    <NuxtLayout
      name="asuostaticlayout"
      :title="$page?.persona.title"
      :image-source="$page?.persona.heroImage"
      :is-sticky-brand-nav="false"
      staticRfiBgColor="#fff"
      hero-section-id="admission-hero"
    >
      <NavbarStickyAtlas
        :nav-items="navItem"
        title="On this page:"
        :always-show-sticky-slot="false"
      >
        <template #stuckMenu>
          <div
            class="d-flex justify-content-end flex-direction-row mb-space-xxxs"
          >
            <ButtonApplyNow
              type="modal"
              event-region="main content"
              event-section="secondary navbar"
            >
            </ButtonApplyNow>
            <button
              @click="smoothScrollTo('asuo-hero-rfi-section')"
              class="btn btn-secondary fs-medium fw-bold ms-space-sm"
            >
              Request info
            </button>
          </div>
        </template>
      </NavbarStickyAtlas>
      <!-- admission section -->
      <SectionIntroFalcon
        :bg-image-source="$page?.persona.admissionBgImage!"
        :image-source="$page?.persona.admissionImage!"
        :title="$page?.persona.admissionTitle!"
        image-position="right"
        container-bg-variant="light-3"
        id="persona-admission-section"
      >
        <template #body>
          <div
            class="mb-space-sm"
            v-html="$page?.persona.admissionDescription"
          ></div>
          <div v-if="$page?.persona.admissionCta">
            <NuxtLink
              class="btn btn-secondary"
              :to="$page?.persona.admissionCta.uri!"
              :aria-label="$page?.persona.admissionCta.text"
              @click.native="
                handleLinkEvent(
                  $event,
                  $page?.persona.admissionTitle!,
                  $page?.persona.admissionCta?.text!
                )
              "
              >{{ $page?.persona.admissionCta.text }}</NuxtLink
            >
          </div>
        </template>
      </SectionIntroFalcon>
      <!-- introduction section -->
      <SectionApollo
        :image="$page?.persona.introductionImage!"
        :title="$page?.persona.introductionTitle!"
        id="persona-details-section"
      >
        <div v-html="$page?.persona.introductionDescription!"></div>
      </SectionApollo>
      <!-- why choose asu section -->
      <SectionCardAtlas
        :bg-image-source="$page?.persona.whyChooseImage!"
        :title="$page?.persona.whyChooseTitle!"
        title-level="h2"
        :image-alt="$page?.persona.whyChooseImageAlt!"
        :icon-source="$page?.persona.whyChooseCard?.icon!"
        :card-text="$page?.persona.whyChooseCard?.text!"
        class="bg-dark-3"
        card-border-variant="light-3"
        id="why-choose-section"
      >
        <template #body
          ><div
            class="text-white"
            v-html="$page?.persona.whyChooseDescription"
          ></div
        ></template>
      </SectionCardAtlas>
      <!-- stats section optional -->
      <section
        v-if="$page?.persona.statsTitle"
        class="py-0 py-md-space-lg py-lg-space-xxl rds-lazy stats-image"
        :data-background-image="$page?.persona.statsBgImage"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 offset-lg-1 col-lg-10 px-0">
              <div class="bg-dark-3 px-space-xs py-space-lg p-lg-space-xl">
                <div
                  class="stat-title ps-space-xxs ps-md-0 mb-space-md mb-lg-space-lg"
                >
                  <h2
                    class="h2-small px-md-space-xxs py-space-xxs bg-secondary d-inline d-lg-inline-block stat-title-bg"
                  >
                    {{ $page.persona.statsTitle }}
                  </h2>
                </div>

                <!-- stat items -->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div
                      v-for="(item, index) in firstStatsList"
                      :key="index"
                      class="mb-space-md mb-lg-space-lg"
                    >
                      <p
                        class="text-light-2 fw-bold stat-item-size mb-space-xxs"
                      >
                        {{ item?.title }}
                      </p>
                      <div class="hr-line bg-secondary mb-space-xs"></div>
                      <div
                        class="text-light-2 fs-xl fw-bold"
                        v-html="item?.description"
                      ></div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div
                      v-for="(item, index) in secondStatsList"
                      :key="index"
                      class="mb-space-md mb-lg-space-lg"
                    >
                      <p class="text-light-2 fw-bold stat-item-size">
                        {{ item?.title }}
                      </p>
                      <div class="hr-line bg-secondary mb-space-md"></div>
                      <div
                        class="text-light-2 fs-xl fw-bold"
                        v-html="item?.description"
                      ></div>
                    </div>
                  </div>
                </div>

                <hr class="text-white mb-space-md mb-lg-space-lg" />

                <!-- list items -->
                <div class="row" v-show="$page.persona.leftListTitle">
                  <div class="col-12 col-lg-6">
                    <h3 class="h3-large text-light-2 mb-space-md">
                      {{ $page.persona.leftListTitle }}
                    </h3>

                    <ListItemCircular
                      v-for="(item, index) in $page.persona.leftListItems"
                      :key="index"
                      number-variant="dark-3"
                      number-bg-color="var( --rds-secondary, #ffc627)"
                      class="mb-space-xs"
                      :number="item?.title!"
                      ><div
                        class="text-secondary fw-bold"
                        v-html="item?.description"
                      ></div
                    ></ListItemCircular>
                  </div>
                  <div
                    v-if="$page.persona.rightListTitle"
                    class="col-12 col-lg-6"
                  >
                    <h3 class="h3-large text-light-2 mb-space-md">
                      {{ $page.persona.rightListTitle }}
                    </h3>
                    <ListItemCircular
                      v-for="(item, index) in $page.persona.rightListItems"
                      :key="index"
                      number-bg-color="var( --rds-secondary, #ffc627)"
                      class="mb-space-xs"
                      :number="item?.title!"
                      ><div
                        class="text-secondary fw-bold"
                        v-html="item?.description"
                      ></div
                    ></ListItemCircular>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- testimonial section optional -->
      <SectionTestimonialDelta
        class="py-space-lg py-lg-space-xxxl"
        v-if="$page?.persona.testimonial"
        :image-source="$page?.persona.testimonial.authorImage!"
        image-alt="testimonial quote"
        :title="$page?.persona.testimonialTitle!"
        :text="$page?.persona.testimonial.quote!"
        :author-text="$page?.persona.testimonial.authorName!"
        :designation-text="$page?.persona.testimonial.authorDesignation!"
        text-align-mobile="center"
        text-align-desktop="left"
      ></SectionTestimonialDelta>
      <!-- apply and resources section -->
      <section
        class="bg-light-3 py-space-lg py-lg-space-xxl"
        id="persona-resources-section"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="h2-medium pb-space-sm">
                {{ $page?.persona.applyTitle }}
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-10">
              <div
                class="fs-medium pb-space-md pb-lg-space-lg line-height-22"
                v-html="$page?.persona.applyDescription"
              ></div>
            </div>
          </div>

          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <div
              v-for="(card, index) in $page?.persona.applyCards"
              :key="index"
              class="col mb-space-sm mb-lg-0"
            >
              <CardInfoVertical
                class="h-100"
                :title="card?.title!"
                title-size="medium"
                :display-title-highlight="false"
                :image-source="card?.image!"
                :cta-as-button="true"
                cta-button-variant="secondary"
                cta-text-variant="dark-3"
                cta-text-size="medium"
                cta-text-weight="bold"
                :cta-text="card?.link?.text!"
                :cta-link="card?.link?.uri!"
                :cta-link-target="formatLinkTarget(card?.link?.target!)"
                @civCtaClick="
                  handleLinkEvent(
                    $event,
                    card?.title!,
                    card?.link?.text!,
                    $page?.persona.applyTitle!,
                    card?.link?.uri!,
                    card?.link?.target!
                  )
                "
              >
                <div class="mb-space-sm" v-html="card?.text"></div>
              </CardInfoVertical>
            </div>
          </div>
        </div>
      </section>
      <!-- faq section -->
      <section
        class="section-bg rds-lazy py-0 py-md-space-lg py-lg-space-xxl"
        :data-background-image="`${$page?.persona.faqBgImage}`"
        id="persona-faq-section"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 offset-lg-1 col-lg-10 px-0">
              <div class="bg-white px-space-xs py-space-lg p-lg-space-xl">
                <h2 class="h2-medium mb-space-sm">
                  {{ $page?.persona.faqTitle }}
                </h2>
                <div class="hr-line bg-secondary mb-space-md"></div>

                <CollapseItem
                  v-for="(faq, index) in $page?.persona.faqItems"
                  :key="index"
                  :collapse-id="`faq-${index}`"
                  :display-number="false"
                  :title="faq?.title!"
                  title-size="medium"
                  class="bg-white mb-space-xs mb-md-space-sm"
                  @collapseShown="
                    handleCollapseEvent(
                      'open',
                      $page?.persona.faqTitle!,
                      faq?.title!,
                      'accordion - faq'
                    )
                  "
                  @collapseHidden="
                    handleCollapseEvent(
                      'close',
                      $page?.persona.faqTitle!,
                      faq?.title!,
                      'accordion - faq'
                    )
                  "
                >
                  <div v-html="faq?.description"></div>
                </CollapseItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- news article section optional -->
      <section
        v-if="
          $page?.persona.newsArticles && $page?.persona.newsArticles?.length > 0
        "
        class="py-space-lg py-lg-space-xxl section-bg rds-lazy"
        :data-background-image="`${$page.persona.newsBgImage}`"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="h2-medium mb-space-sm">
                {{ $page.persona.newsTitle }}
              </h2>
              <div class="hr-line bg-secondary mb-space-md"></div>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <div
              class="col mb-space-sm"
              v-for="(article, index) in $page.persona.newsArticles"
              :key="index"
            >
              <card-image-article
                class="h-100"
                :clickable-card="true"
                border-variant="light-4"
                :image-source="article?.coverImage!"
                :image-alt="article?.title!"
                :title="article?.title!"
                :date="formatDate(article?.updatedDate!)"
                :minutes="article?.timeToRead!"
                :cta-link="article?.detailPage!"
                cta-text="Read more"
                @cardClick="
                  handleCardArticleClick(
                    $event,
                    $page?.persona.newsTitle!,
                    article?.title!,
                    article?.detailPage!,
                    'card - article'
                  )
                "
                @ctaClick="
                  handleCardArticleClick(
                    $event,
                    $page?.persona.newsTitle!,
                    article?.title!,
                    article?.detailPage!,
                    'card - article'
                  )
                "
                @cardEnter="handleEnterKeyEvent(article?.detailPage!)"
              >
              </card-image-article>
            </div>
          </div>
        </div>
      </section>
    </NuxtLayout>
  </div>
</template>
<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CardInfoVertical } from "@rds-vue-ui/card-info-vertical";
import CollapseItem from "@rds-vue-ui/collapse-item/CollapseItem.vue";
import { ListItemCircular } from "@rds-vue-ui/list-item-circular";
import { NavbarStickyAtlas } from "@rds-vue-ui/navbar-sticky-atlas";
import { SectionApollo } from "@rds-vue-ui/section-apollo";
import { SectionCardAtlas } from "@rds-vue-ui/section-card-atlas";
import { SectionIntroFalcon } from "@rds-vue-ui/section-intro-falcon";
import { SectionTestimonialDelta } from "@rds-vue-ui/section-testimonial-delta";
import ButtonApplyNow from "~/components/nav/ButtonApplyNow.vue";
import { Persona } from "~/server/resolvers/resolvers-types";
const schemaService = useSchemaService();
const route = useRoute();
const siteUrl = useRuntimeConfig().public.siteUrl;
interface IPage {
  persona: Persona;
}
const navItem = {
  "persona-admission-section": "Admission",
  "persona-details-section": "Details",
  "persona-resources-section": "Resources",
  "persona-faq-section": "FAQ",
};
/*
********************************************
Page quires
********************************************
*/

const $page = await usePageQuery<IPage>(
  `#graphql
      query Persona($slug: String!) {
        persona(slug: $slug) {
          id
            detailPage
            metaTitle
            metaDescription
            title
            description
            heroImage
            heroImageAlt
            introductionTitle
            introductionImage
            introductionDescription
            whyChooseTitle
            whyChooseImage
            whyChooseImageAlt
            whyChooseDescription
            whyChooseCard {
              id
              text
              icon
            }
            admissionBgImage
            admissionImage
            admissionImageAlt
            admissionTitle
            admissionDescription
            admissionCta {
              uri
              target
              text
            }
            statsTitle
            statsBgImage
            stats {
              id
              title
              description
            }
            leftListTitle
            leftListItems {
              id
              title
              description
            }
            rightListTitle
            rightListItems {
              id
              title
              description
            }
            testimonialTitle
            testimonial {
              quote
              authorName
              authorDesignation
              authorImage
            }
            applyTitle
            applyDescription
            applyCards {
              title
              text
              image
              link {
                uri
                target
                text
              }
            }
            faqBgImage
            faqTitle
            faqItems {
              id
              title
              description
            }
            newsBgImage
            newsTitle
            newsArticles {
              coverImage
              title
              timeToRead
              updatedDate
              changed
              detailPage
              publishedDate
            }
        }
      }
      `,
  {
    slug: route?.params?.slug as string,
  },
  {
    key: `admissions-${route?.params?.slug as string}`,
  }
);

/*
********************************************
Page meta tags
********************************************
*/
definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
});
useHead({
  title: $page?.persona.metaTitle,
  meta: [
    {
      name: "Title",
      content: $page?.persona.metaTitle,
    },
    {
      name: "Description",
      content: $page?.persona.metaDescription,
    },
    ...useUtilityService().generateSocialTags(
      $page?.persona.metaTitle as string,
      $page?.persona.metaDescription as string,
      $page?.persona.heroImage as string
    ),
  ],
  link: [
    {
      rel: "canonical",
      href: useUtilityService().formatCanonicalUrl(
        `${siteUrl}${$page?.persona.detailPage}`
      ),
    },
  ],
  script: [schemaService.generatePersonaSchema($page?.persona)],
});

const smoothScrollTo = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    analyticsComposable.trackEvent({
      event: "link",
      name: "onclick",
      action: "click",
      type: "internal link",
      region: "navbar",
      section: "sticky navbar",
      text: "request info",
      component: "sticky navbar",
    });
  }
};
const formatLinkTarget = (target: string) => {
  return useUtilityService().formatLinkTarget(target);
};
const handleLinkEvent = (
  event: Event,
  section: string,
  text: string,
  component?: string,
  uri?: string,
  target?: string
) => {
  event.preventDefault();
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    text.toLowerCase(),
    component ? component.toLowerCase() : undefined
  );
  if (!uri && !target) return;
  const formattedTarget = formatLinkTarget(target as string);
  navigateTo(uri, {
    open: {
      target: formattedTarget,
    },
  });
};
let half = Math.ceil(($page?.persona.stats?.length ?? 0) / 2);
const firstStatsList = computed(() => {
  return $page?.persona.stats?.slice(0, half);
});
const secondStatsList = computed(() => {
  return $page?.persona.stats?.slice(half);
});
const handleCollapseEvent = (
  action: string,
  section: string,
  text: string,
  component: string
) => {
  analyticsComposable.trackCollapseEvent(
    "onclick",
    action,
    "click",
    "main content",
    section.toLowerCase(),
    text.toLowerCase(),
    component
  );
};
const formatDate = (date: string) => {
  return useUtilityService().formatDate(date);
};

const handleCardArticleClick = (
  event: Event,
  section: string,
  text: string,
  uri: string,
  component: string
) => {
  event.preventDefault();
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    text.toLowerCase(),
    component
  );
  navigateTo(uri, {
    open: {
      target: "_self",
    },
  });
};
const handleEnterKeyEvent = (link: string) => {
  navigateTo(link, {
    open: {
      target: "_self",
    },
  });
};
</script>
<style lang="scss" scoped>
:deep(.card-icon-height) {
  height: 47px;
}
.stat-title {
  max-width: 95%;
}
.stat-title-bg {
  box-shadow: 0.5em 0 0 #ffc637, -0.5em 0 0 #ffc627;
  line-height: 1.6;
}
.section-bg {
  position: relative;
  background-size: auto;
  background-position: top;
}
.hr-line {
  width: 36px;
  height: 8px;
}
.stat-item-size {
  font-size: 60px;
}

:deep(.card-icon-height) {
  height: 47px;
}

#why-choose-section,
.lic-container {
  :deep(a) {
    color: #ffc627 !important;
    &:focus {
      outline: 2px solid #ffc627;
    }
  }
}

/* Large devices (desktops, 768px and up) */
@media (min-width: 768px) {
  .stat-title {
    max-width: none;
  }
  .stat-title-bg {
    box-shadow: none;
    line-height: 1.2;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .stats-image {
    min-height: 950px;
    background-size: cover;
    background-position: center 5%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}
:deep(.btn) {
  text-decoration: none !important;
}
</style>
